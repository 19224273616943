.App {
  text-align: center;
} 

h1 {
  font-family: 'Special Elite', cursive;
  margin: 8px 0 0 0;
}

.header {
  font-family: Futura,Trebuchet MS,Arial,sans-serif; 
  letter-spacing: 4px; 
  text-transform: uppercase;
  font-size: 1.8em;
}

.heroDiv {
  padding-top: 50px;
}

.heroImg {
  height: 650px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100%;
  position: relative;
  display: flex;
  width: 100%;
}

div.heroImg::after {
  content: "";
  background: url("./images/hero.png");
  filter: blur(10px) brightness(60%);
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
  height: 1000px;
  /* background-repeat: no-repeat; */
  background-position: center top;
  background-size: 100%;
}

.heroText1 {
  text-shadow: black 2px 2px 2px;
  font-size: 40px;
}

.heroText2 {
  text-shadow: black 2px 2px 2px;
}

/* screen size variants */
/* big screen */
@media screen and (min-width: 1001px) {
  .hide {
    width: 100%;
  }
  img.navLogo {
    height: 50px;
    padding: 15px 2%;
  }
  img.mobileNavLogo {
    display: none;
  }
  div.heroImg::after {
    top: 0;
  }
  .heroDiv {
    width: 50em;
    text-align: left;
  }
  .heroSpotlight {
    height: 30vw;
  }
  .heroText1 {
    font-size: "4vw"
  }
  .heroText2 {
    font-size: "3.2vw"
  }
  .heroButton {
    font-size: 1.8vw;
  }
  .collapsedBtn {
    display: none;
  }
  .content {
    display: none;
  }
}

/* medium screen */
@media screen and (min-width: 701px) and (max-width: 1000px) {
  .hide {
    display: none;
  }
  img.navLogo {
    display: none;
  }
  img.mobileNavLogo {
    height: 50px;
    padding: 15px 2%;
  }
  div.heroImg::after {
    top: 0;
  }
  .heroDiv {
    width: 50em;
    text-align: left;
  }
  .heroSpotlight {
    height: 50vw;
  }
  .heroText1 {
    font-size: "4vw"
  }
  .heroText2 {
    font-size: "3.2vw"
  }
  .heroButton {
    font-size: 1.8vw;
  }
  /* collapsible navbar content */
  .content {
    overflow: hidden;
    transition: max-height 1s ease-in-out;
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-content: flex-start;
    cursor: pointer;
    background-color: black;
  }
  .content-active {
    overflow: hidden;
    animation: fadein .5s;
    animation-timing-function:ease-in-out;
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-content: flex-start;
    cursor: pointer;
    background-color: black;
  }
}

/* small screen */
@media screen and (max-width: 700px) {
  img.navLogo {
    height: 50px;
    padding: 15px 2%;
  }
  img.mobileNavLogo {
    height: 50px;
    padding: 15px 2%;
  }
  div.heroImg::after {
    top: 1.5em;
    /* left: -2em; */
    /* width: 120%; */
  }
  .heroDiv {
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-top: -3em;
  }
  .heroSpotlight {
    width: 70%;
  }
  .heroText1 {
    font-size: "15em"
  }
  .heroText2 {
    font-size: "12vw"
  }
  .heroButton {
    font-size: 5vw;
  }
  .musicDiv {
    margin-top: -6em;
  }
  .hide {
    display: none;
  }
  /* collapsible navbar content */
  .content {
    overflow: hidden;
    transition: max-height 1s ease-in-out;
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-content: flex-start;
    cursor: pointer;
    background-color: black;
  }
  .content-active {
    overflow: hidden;
    animation: fadein .5s;
    animation-timing-function:ease-in-out;
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-content: flex-start;
    cursor: pointer;
    background-color: black;
  }
}

/* fade in */
div.heroDiv {
    height: 100%;
    color: white;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
          animation-timing-function:ease-in-out;
          /* animation-delay: 1s; */
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* .navItems {
  font-family: Futura,Trebuchet MS,Arial,sans-serif; 
  letter-spacing: 4px; 
  text-transform: uppercase;
  z-index: 1;
  color: white;
  padding: 30px 15px;
  font-size: 1em;
  cursor: pointer;
} */

.heroButton { 
  color: white;
  cursor: pointer;
  padding: 13px 30px 10px 30px;
  background-color: rgba(0,0,0,0.5);
  border: 3px solid #b90707;
}

.heroButton:hover {
  background-color: #b90707;
}

.container {
  display: flex;
  justify-content: center;
  align-self: center;
  /* margin: 100px 0; */
  margin-top: 0em;
  height: 100%;
  background-color: #2b2b2bd0;
  width: 80%;
  color: #cacacf;
  padding-top: 30px;
  padding-bottom: 20px;
}

.fa {
  padding: 10px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
  background: var(--text-color);
  color: var(--bg-color);
}